import { ButtonLink, Stack, Text, Flex } from '@gr4vy/poutine-react'
import { env } from 'shared/constants/env'
import { Step } from './Step'

export const FinishStep = () => (
  <Step title="You are all set up now!">
    <Stack gap={16}>
      <Text variant="med2">
        Congratulations on making your first transaction. Our team is here to
        help you answer any questions.
      </Text>
      <Flex gap={24} alignItems="center">
        <ButtonLink
          href={env.REACT_APP_SUPPORT_URL || 'https://support.gr4vy.com'}
          opensInNewWindow
        >
          Support
        </ButtonLink>
        <ButtonLink
          href="https://docs.gr4vy.com"
          variant="secondary"
          opensInNewWindow
        >
          Documentation
        </ButtonLink>
      </Flex>
    </Stack>
  </Step>
)
