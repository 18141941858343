import {
  Alert,
  Checkbox,
  Heading,
  Stack,
  Text,
  TextLink,
} from '@gr4vy/poutine-react'
import { Form } from 'antd'
import { useParams } from 'react-router-dom'
import { EditPaymentServiceForm } from 'connections/components/EditPaymentServiceForm/EditPaymentServiceForm'
import { usePaymentServiceAndDefinition } from 'connections/hooks/use-payment-service-and-definition'
import { CredentialField } from 'shared/components/CredentialField/CredentialField'
import { FormItem } from 'shared/components/FormItem'
import { Loading } from 'shared/components/Loading'
import { pathTo } from 'shared/paths/reports'
import {
  AccessLevel,
  Resource,
  useResourcePermission,
} from 'shared/permissions'

export const PaymentServiceSettlementReporting = () => {
  const { merchantAccountId } = useParams<{
    merchantAccountId: string
  }>()
  const settlementReportingEnabled = Form.useWatch('settlementReportingEnabled')
  const { paymentService, definition, isLoading } =
    usePaymentServiceAndDefinition()
  const hasPermission = useResourcePermission(
    Resource.connections,
    AccessLevel.write
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {paymentService?.settlementReportingEnabled && merchantAccountId && (
        <Alert variant="information">
          <Alert.Icon />
          <Alert.Content>
            <Alert.Title>Settlement reporting schedule</Alert.Title>
            <Alert.Text>
              Next, to include the data received from the payment service in
              Gr4vy’s consolidated settlement reports, you need to enable the
              settlement report schedule in{' '}
              <TextLink
                href={pathTo.allReports(merchantAccountId, 'financial')}
              >
                Reports
              </TextLink>
              .
            </Alert.Text>
          </Alert.Content>
        </Alert>
      )}
      <Stack gap={24}>
        <Stack>
          <Text as="span">
            Enable this connection to ingest settlement details from the payment
            service.
          </Text>
          <FormItem
            name={['settlementReportingEnabled']}
            valuePropName="checked"
            trigger="onCheckedChange"
            shouldUpdate
            help={
              !settlementReportingEnabled && (
                <Text as="span" paddingTop={4} fontSize="xs">
                  When disabled the settlement data will not be ingested from
                  the payment service.
                </Text>
              )
            }
          >
            <Checkbox disabled={!hasPermission}>Enable settlement</Checkbox>
          </FormItem>
        </Stack>
        {settlementReportingEnabled &&
          !!definition?.reportingFields?.length && (
            <Stack gap={16}>
              <Stack gap={8}>
                <Heading level={5}>Credentials</Heading>
                <Text>
                  Learn how to configure {definition.displayName} in{' '}
                  <TextLink
                    href={`https://docs.gr4vy.com/connections/payments/${definition.id}`}
                    opensInNewWindow
                  >
                    this quick start guide.
                  </TextLink>{' '}
                  We will help you find all the information you need to set up
                  your payment service correctly.
                </Text>
              </Stack>
              <Stack gap={24}>
                {definition.reportingFields.map((item) => (
                  <CredentialField
                    key={item.key}
                    item={item}
                    required={item.required}
                    secret={item.secret}
                    group="reportingFields"
                    requiredMessage="This field is required"
                  />
                ))}
              </Stack>
            </Stack>
          )}
      </Stack>
    </>
  )
}

export const PaymentServiceSettlementReportingPage = () => (
  <EditPaymentServiceForm submitButtonText="Save changes">
    <PaymentServiceSettlementReporting />
  </EditPaymentServiceForm>
)
