import { Button, Flex, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Form, Select } from 'antd'
import { Dayjs } from 'dayjs'
import { capitalize } from 'lodash'
import { filter } from 'connections/components/Filters/select-filter'
import { ReportPayload } from 'reports/services/report'
import { DateTimeRangePicker } from 'shared/components/DateTimeRangePicker'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import {
  Timezone,
  getTimezones,
  formatTimestamp,
} from 'shared/helpers/timezone'

interface Props {
  isLoading: boolean
  schedule?: boolean
  onSubmit: (data: {
    timezone: string
    from?: string
    to?: string
    schedule?: ReportPayload['schedule']
  }) => void
  onClose: () => void
  autoFocus?: boolean
}

interface FormData {
  timezone: string
  range: [Dayjs, Dayjs]
  schedule: ReportPayload['schedule']
}

const timezones = getTimezones().sort((a, b) => (a.offset < b.offset ? -1 : 1))

export const TemplatedReportForm = ({
  isLoading,
  schedule,
  onSubmit,
  onClose,
  autoFocus = true,
}: Props) => {
  const [form] = Form.useForm<FormData>()
  const initialValues = {
    range: undefined,
    schedule: undefined,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
  const handleSubmit = (data: FormData) => {
    const timezone = timezones.find(
      ({ code }) => code === data.timezone
    ) as Timezone
    onSubmit({
      timezone: data.timezone,
      schedule: data.schedule,
      from: schedule ? undefined : formatTimestamp(data.range[0], timezone),
      to: schedule ? undefined : formatTimestamp(data.range[1], timezone),
    })
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      autoComplete="off"
      layout="vertical"
      requiredMark={false}
    >
      <Stack gap={32}>
        <Stack gap={24}>
          <Heading level={5}>
            {schedule ? 'Set frequency' : 'Set date and time'}
          </Heading>
          <FormItem
            label={<Label>Time zone</Label>}
            name="timezone"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
            <Select
              listItemHeight={32}
              placeholder="Select an option"
              style={{ width: '100%' }}
              dropdownStyle={{ pointerEvents: 'auto' }}
              filterOption={filter}
              showSearch
            >
              {timezones.map(({ code, name, offsetFormatted }) => (
                <Select.Option key={code} value={code}>
                  <Flex justifyContent="space-between" width="full">
                    <Text as="span">{name}</Text>
                    <Text
                      as="span"
                      style={{
                        fontVariantNumeric: 'tabular-nums',
                      }}
                    >
                      UTC{offsetFormatted}
                    </Text>
                  </Flex>
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          {!schedule && (
            <FormItem
              label={<Label>Date and time</Label>}
              name="range"
              rules={[
                {
                  required: true,
                  message: 'A date and time range is required',
                },
              ]}
            >
              <DateTimeRangePicker
                autoFocus={autoFocus}
                placeholder={['Starting from', 'Ending at']}
                style={{ width: '100%' }}
                popupStyle={{ pointerEvents: 'auto' }}
              />
            </FormItem>
          )}
          {schedule && (
            <FormItem
              label={<Label>Frequency</Label>}
              name="schedule"
              rules={[
                {
                  required: true,
                  message: 'This field is required',
                },
              ]}
            >
              <Select
                autoFocus={autoFocus}
                listItemHeight={32}
                placeholder="Select an option"
                style={{ width: '100%' }}
                dropdownStyle={{ pointerEvents: 'auto' }}
              >
                {['daily', 'weekly', 'monthly'].map((frequency) => (
                  <Select.Option key={frequency} value={frequency}>
                    {capitalize(frequency)}
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          )}
        </Stack>
        <Flex gap={16}>
          <Button
            type="submit"
            loading={isLoading}
            loadingText={schedule ? 'Scheduling' : 'Generating'}
          >
            {schedule ? 'Schedule' : 'Generate'}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Flex>
      </Stack>
    </Form>
  )
}
