import {
  Box,
  Container,
  Stack,
  Description,
  TextLine,
} from '@gr4vy/poutine-react'
import { useParams } from 'react-router-dom'
import { useGiftCardService } from 'connections/hooks/use-gift-card-service'
import { useGiftCardServiceDefinition } from 'connections/hooks/use-gift-card-service-definition'
import GiftCardPageLayout from 'gift-cards/components/GiftCardPageLayout/GiftCardPageLayout'
import { formatGiftCardExpirationDate } from 'gift-cards/helpers'
import { useGiftCard } from 'gift-cards/hooks/use-gift-card'
import { SummaryGrid } from 'shared/components/SummaryGrid'
import { BuyerPanel } from 'transactions/components/BuyerPanel'
import { giftCardIconUrl } from 'transactions/constants/giftCards'

const GiftCardOverviewPage = () => {
  const { giftCardId, merchantAccountId } = useParams<{
    giftCardId: string
    merchantAccountId: string
  }>()

  const { giftCard } = useGiftCard(giftCardId)

  const { giftCardService } = useGiftCardService(
    merchantAccountId,
    giftCard?.giftCardService.id
  )
  const { giftCardServiceDefinition } = useGiftCardServiceDefinition(
    merchantAccountId,
    giftCardService?.giftCardServiceDefinitionId
  )

  return (
    <GiftCardPageLayout>
      <Stack gap={32}>
        <Box>
          <Container>
            <SummaryGrid
              paddingX={32}
              paddingY={48}
              gridTemplateColumns={{ xs: 1, md: 2, lg: 3 }}
            >
              <SummaryGrid.Item>
                <SummaryGrid.Key>Type</SummaryGrid.Key>
                <SummaryGrid.Value>
                  <Description>
                    <Description.Icon src={giftCardIconUrl} size={24} />
                    <Description.Text>Gift card</Description.Text>
                  </Description>
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Connection</SummaryGrid.Key>
                <SummaryGrid.Value>
                  <Description>
                    <Description.Icon
                      size={24}
                      src={giftCardServiceDefinition?.iconUrl}
                    />
                    {!!giftCardServiceDefinition?.displayName && (
                      <Description.Text>
                        {giftCardServiceDefinition?.displayName}
                      </Description.Text>
                    )}
                  </Description>
                </SummaryGrid.Value>
              </SummaryGrid.Item>
              <SummaryGrid.Item>
                <SummaryGrid.Key>Expiration</SummaryGrid.Key>
                <SummaryGrid.Value>
                  <TextLine display="block">
                    {formatGiftCardExpirationDate(giftCard?.expirationDate)}
                  </TextLine>
                </SummaryGrid.Value>
              </SummaryGrid.Item>
            </SummaryGrid>
          </Container>
        </Box>
        {giftCard?.buyer && (
          <Box paddingX={8} paddingBottom={80}>
            <Container>
              <BuyerPanel data={giftCard} />
            </Container>
          </Box>
        )}
      </Stack>
    </GiftCardPageLayout>
  )
}

export default GiftCardOverviewPage
