import { Flex, Heading, Stack, Text } from '@gr4vy/poutine-react'
import { Select } from 'antd'
import { capitalize } from 'lodash'
import { filter } from 'connections/components/Filters/select-filter'
import { DateTimeRangePicker } from 'shared/components/DateTimeRangePicker'
import { Label } from 'shared/components/Form'
import { FormItem } from 'shared/components/FormItem'
import { maxTagPlaceholderFormatter } from 'shared/helpers/maxTagPlaceholderFormatter'
import { getTimezones } from 'shared/helpers/timezone'
import { TransactionStatus } from 'shared/services/transactions'
import { getTransactionStatusConfigs } from 'transactions/constants/transactionStatuses'

interface Props {
  schedule?: boolean
  visible: boolean
}

interface Status {
  value: TransactionStatus
  label: string
}

const timezones = getTimezones().sort((a, b) => (a.offset < b.offset ? -1 : 1))
const transactionStatuses = Object.entries(
  getTransactionStatusConfigs()
).reduce((acc: Status[], [key, { label }]) => {
  return [...acc, { value: key as TransactionStatus, label }]
}, [])

export const StepDate = ({ schedule, visible }: Props) => {
  return (
    <Stack gap={24} display={visible ? 'flex' : 'none'}>
      <Stack gap={8}>
        <Heading level={5}>
          {schedule ? 'Set frequency' : 'Set date and time'}
        </Heading>
        {schedule ? (
          <Text>
            The frequency requires that transaction statuses are specified.
            Transactions that reached those statuses within their lifecycle will
            be included.
          </Text>
        ) : (
          <Text>
            The date and time range requires that a transaction status is
            specified. All instances that contain this status within their
            lifecycle for the range you specify will be included in your report.
          </Text>
        )}
      </Stack>
      <FormItem label={<Label>Transaction status</Label>} name="status">
        <Select
          listItemHeight={32}
          placeholder="All statuses"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          mode="multiple"
          maxTagCount="responsive"
          maxTagPlaceholder={maxTagPlaceholderFormatter}
          allowClear
        >
          {transactionStatuses.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem
        label={<Label>Time zone</Label>}
        name="timezone"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <Select
          listItemHeight={32}
          placeholder="Select an option"
          style={{ width: '100%' }}
          dropdownStyle={{ pointerEvents: 'auto' }}
          filterOption={filter}
          showSearch
        >
          {timezones.map(({ code, name, offsetFormatted }) => (
            <Select.Option key={code} value={code}>
              <Flex justifyContent="space-between" width="full">
                <Text as="span">{name}</Text>
                <Text
                  as="span"
                  style={{
                    fontVariantNumeric: 'tabular-nums',
                  }}
                >
                  UTC{offsetFormatted}
                </Text>
              </Flex>
            </Select.Option>
          ))}
        </Select>
      </FormItem>
      {!schedule && (
        <FormItem
          label={<Label>Date and time</Label>}
          name="range"
          rules={[
            {
              required: true,
              message: 'A date and time range is required',
            },
          ]}
        >
          <DateTimeRangePicker
            placeholder={['Starting from', 'Ending at']}
            style={{ width: '100%' }}
            popupStyle={{ pointerEvents: 'auto' }}
          />
        </FormItem>
      )}
      {schedule && (
        <FormItem
          label={<Label>Frequency</Label>}
          name="schedule"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Select
            listItemHeight={32}
            placeholder="Select an option"
            style={{ width: '100%' }}
            dropdownStyle={{ pointerEvents: 'auto' }}
          >
            {['daily', 'weekly', 'monthly'].map((frequency) => (
              <Select.Option key={frequency} value={frequency}>
                {capitalize(frequency)}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      )}
    </Stack>
  )
}
