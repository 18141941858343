import {
  Divider,
  Dropdown,
  Flex,
  Heading,
  Icon,
  Stack,
} from '@gr4vy/poutine-react'
import { env } from 'shared/constants/env'
import { Menu } from './Menu'

export const SupportMenu = () => {
  return (
    <Menu tooltipContent="Support" trigger={<Icon name="help" />}>
      <Stack direction="column" gap={8} padding={16} color="gray100">
        <Stack paddingX={8} direction="column">
          <Heading level={6} as="h6">
            Support
          </Heading>
        </Stack>
        <Divider />
        <Dropdown.Item
          onSelect={() => window.open('https://docs.gr4vy.com', '_blank')}
        >
          <Flex alignItems="center" gap={8}>
            Documentation
            <Flex justifyContent="center" width={20}>
              <Icon name="external-link" />
            </Flex>
          </Flex>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={() =>
            window.open(
              env.REACT_APP_SUPPORT_URL || 'https://support.gr4vy.com',
              '_blank'
            )
          }
        >
          <Flex alignItems="center" gap={8}>
            Help desk
            <Flex justifyContent="center" width={20}>
              <Icon name="external-link" />
            </Flex>
          </Flex>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={() => window.open('https://status.gr4vy.com', '_blank')}
        >
          <Flex alignItems="center" gap={8}>
            Status
            <Flex justifyContent="center" width={20}>
              <Icon name="external-link" />
            </Flex>
          </Flex>
        </Dropdown.Item>
      </Stack>
    </Menu>
  )
}
