import {
  atoms,
  ButtonCopy,
  Heading,
  Skeleton,
  Stack,
  Text,
} from '@gr4vy/poutine-react'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { giftCardTabName } from 'gift-cards/constants'
import { useGiftCard } from 'gift-cards/hooks/use-gift-card'
import { PageLayout } from 'shared/components/PageLayout'
import { GiftCardTabs } from 'shared/constants'
import { dateFormatHeader } from 'shared/helpers/date-format'
import { pathTo } from 'shared/paths/gift-cards'
import { GiftCardMaskedNumber } from 'transactions/components/MaskedCardNumber/GiftCardMaskedNumber'
import { giftCardIconUrl } from 'transactions/constants/giftCards'

interface GiftCardPageLayoutProps {
  children: ReactNode
}
export default function GiftCardPageLayout({
  children,
}: GiftCardPageLayoutProps) {
  const { giftCardId, merchantAccountId } = useParams() as {
    giftCardId: string
    merchantAccountId: string
  }

  const { giftCard, isLoading } = useGiftCard(giftCardId)

  const tabs = [
    {
      title: giftCardTabName[GiftCardTabs.overview],
      url: pathTo.giftCardId(
        merchantAccountId,
        giftCardId,
        GiftCardTabs.overview
      ),
    },
    {
      title: giftCardTabName[GiftCardTabs.transactions],
      url: pathTo.giftCardId(
        merchantAccountId,
        giftCardId,
        GiftCardTabs.transactions
      ),
    },
  ]

  return (
    <PageLayout
      titleChildren={
        <Stack direction="row" gap={16} alignItems="center">
          {isLoading ? (
            <Skeleton width={32}>
              <Skeleton.Box width={32} height={32} borderRadius="rounded" />
            </Skeleton>
          ) : (
            <img
              className={atoms({ height: 32, width: 32 })}
              src={giftCardIconUrl}
            />
          )}
          <Heading level={3} as="h1">
            {giftCard && !isLoading ? (
              <GiftCardMaskedNumber
                cardNumber={{
                  bin: giftCard.bin,
                  subBin: giftCard.subBin,
                  last4: giftCard.last4,
                }}
              />
            ) : (
              <Skeleton width={240}>
                <Skeleton.Box height={32} />
              </Skeleton>
            )}
          </Heading>
        </Stack>
      }
      titleSubChildren={
        giftCard && (
          <Text as="span" color="gray80" fontWeight="normal">
            {dateFormatHeader(giftCard.createdAt)}
          </Text>
        )
      }
      subNavigation={tabs}
      actions={
        <ButtonCopy variant="secondary" copy={document.location.href}>
          Copy link
        </ButtonCopy>
      }
      noContainer
      gap="none"
    >
      {children}
    </PageLayout>
  )
}
