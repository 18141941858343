import { Box, Tag, Tooltip } from '@gr4vy/poutine-react'
import { useTransactionPaymentMethod } from 'transactions/hooks/use-transaction-payment-methods'
import { GiftCardRedemption, PaymentMethod } from 'transactions/services'
import { GiftCardItem } from './GiftCardItem'

export interface PaymentMethodsTagProps {
  paymentMethod: PaymentMethod | null
  giftCardRedemptions?: GiftCardRedemption[]
}
export const PaymentMethodsTag = ({
  paymentMethod,
  giftCardRedemptions = [],
}: PaymentMethodsTagProps) => {
  const {
    isSinglePaymentMethod,
    paymentMethodsCount,
    formattedGiftCardRedemptions,
  } = useTransactionPaymentMethod({
    paymentMethod,
    giftCardRedemptions,
  })

  return !isSinglePaymentMethod && !!paymentMethodsCount ? (
    <Tooltip
      content={formattedGiftCardRedemptions.map((giftCardRedemption, index) => (
        <GiftCardItem key={index} giftCardRedemption={giftCardRedemption} />
      ))}
    >
      <Box marginLeft={16} style={{ flexShrink: 0 }}>
        <Tag variant="subtle" color="neutral">
          +{paymentMethodsCount} more
        </Tag>
      </Box>
    </Tooltip>
  ) : null
}
